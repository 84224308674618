import Bloo from "./../imgs/Marcas/Bloosm.png"
import Desi from "./../imgs/Marcas/Desiderata.png"
import Galp from "./../imgs/Marcas/Galpon.png"
import Ole from "./../imgs/Marcas/Oleana.png"
import Sara from "./../imgs/Marcas/Sarapura.png"
import Tre from "./../imgs/Marcas/Trebol.png"
import Sal from "./../imgs/Marcas/Selve.png"
import Vic from "./../imgs/Marcas/vichka.png"

const imgs = [Desi, Galp, Ole, Sara, Bloo, Tre, Sal, Vic]

export default imgs