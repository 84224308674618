import num1 from "./../imgs/Ginebra/01.JPG"
import num2 from "./../imgs/Ginebra/02-01.JPG"
import num3 from "./../imgs/Ginebra/02-02.JPG"
import num4 from "./../imgs/Ginebra/02-03.JPG"
import num5 from "./../imgs/Ginebra/03-01.JPG"
import num6 from "./../imgs/Ginebra/03-02.JPG"
import num7 from "./../imgs/Ginebra/04-01.JPG"
import num8 from "./../imgs/Ginebra/04-02.JPG"
import num9 from "./../imgs/Ginebra/04-03.JPG"
import num10 from "./../imgs/Ginebra/05-01.JPG"
import num11 from "./../imgs/Ginebra/05-02.mp4"

const ginebraImgs = [num1,num2,num3,num4,num5,num6,num7,num8,num9,num10,num11]

export default ginebraImgs