import num1 from "./../imgs/Estudio Barcelona/Image.png"
import num2 from "./../imgs/Estudio Barcelona/image 1.png"
import num3 from "./../imgs/Estudio Barcelona/image 2.png"
import num4 from "./../imgs/Estudio Barcelona/image 3.png"
import num5 from "./../imgs/Estudio Barcelona/image4.png"
import num6 from "./../imgs/Estudio Barcelona/image 5.png"

const barcelonaImgs = [num1, num2, num3, num4, num5, num6];

export default barcelonaImgs

