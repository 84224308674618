import num1 from "./../imgs/Sapura/num1.jpg"
import num2 from "./../imgs/Sapura/num2.jpg"
import num3 from "./../imgs/Sapura/num3.jpg"
import num4 from "./../imgs/Sapura/num4.jpg"
import num5 from "./../imgs/Sapura/num5.mp4"
import num6 from "./../imgs/Sapura/num6.jpg"
import num7 from "./../imgs/Sapura/num7.jpg"
import num8 from "./../imgs/Sapura/num8.jpg"

const imgs = [num1, num2, num3, num4, num5, num6, num7, num8]

export default imgs;
