import num1 from "./../imgs/Oleana/num1.jpg"
import num2 from "./../imgs/Oleana/num2.jpg"
import num3 from "./../imgs/Oleana/num3.jpg"
import num4 from "./../imgs/Oleana/num4.jpg"
import num5 from "./../imgs/Oleana/num5.jpg"
import num6 from "./../imgs/Oleana/num6.jpg"
import num7 from "./../imgs/Oleana/num7.jpg"

const fotos = [num1, num2, num3, num4, num5, num6, num7]

export default fotos
