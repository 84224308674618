import num1 from "./../imgs/Gine Indumentaria/01.JPG"
import num2 from "./../imgs/Gine Indumentaria/02-01.JPG"
import num3 from "./../imgs/Gine Indumentaria/02-02.JPG"
import num4 from "./../imgs/Gine Indumentaria/03-01.JPG"
import num5 from "./../imgs/Gine Indumentaria/03-02.JPG"
import num6 from "./../imgs/Gine Indumentaria/03-03.JPG"
import num7 from "./../imgs/Gine Indumentaria/04-01.JPG"
import num8 from "./../imgs/Gine Indumentaria/04-02.JPG"
import num9 from "./../imgs/Gine Indumentaria/05.mp4"

const gineIndu = [num1,num2,num3,num4,num5,num6,num7,num8,num9]

export default gineIndu