import num1 from "./../imgs/By Sofia/num1.jpg"
import num2 from "./../imgs/By Sofia/num2.jpg"
import num3 from "./../imgs/By Sofia/num3.jpg"
import num4 from "./../imgs/By Sofia/num4.jpg"
import num5 from "./../imgs/By Sofia/num5.jpg"
import num6 from "./../imgs/By Sofia/num6.jpg"
import num7 from "./../imgs/By Sofia/num7.jpg"
import num8 from "./../imgs/By Sofia/num8.jpg"

const bySofiaImgs = [num1,num2,num3,num4,num5,num6,num7,num8]

export default bySofiaImgs

