
const Error = () => {
  return (
    <>
        <div style={{display: "flex", flexDirection:"column", height: "100%", width:"auto", margin: "0 auto", justifyContent: "center",
        alignItems: "center"}}>
        <h1>Error 404!</h1>
        <p>Page not found</p>
        </div>
    </>
  )
}

export default Error
