import num1 from "./../imgs/SB Collection/01.jpg"
import num2 from "./../imgs/SB Collection/02-01.jpg"
import num3 from "./../imgs/SB Collection/02-02.mp4"
import num4 from "./../imgs/SB Collection/03-01.jpg"
import num5 from "./../imgs/SB Collection/03-02.mp4"
import num6 from "./../imgs/SB Collection/03-03.jpg"
import num7 from "./../imgs/SB Collection/04.jpg"



const sbImgs = [num1,num2,num3,num4,num5,num6,num7]

export default sbImgs